<template>
  <transition name="fade">
    <div class="ui-loading"
         v-if="visible">
      <div class="ui-loading-back">
        <img src="../../assets/images/loading.gif">
        <p class="font">保存中...</p>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "ui-loading",
    props: {
      visible: { //控制loading显隐
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style scoped>
  .ui-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .ui-loading-back {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .font {
    margin-top: 20px;
    color: rgba(0, 0, 0, .3);
    font-size: 14px;
  }

  .ui-loading-back > img {
    width: 150px;
    height: 150px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: all .5s;
  }

  .fade-enter, .fade-leave-to {
    height: 0;
  }
</style>
