<template>
  <div class="profile">
    <div class="profile_one">
      <img :src="imgUrl ? imgUrl: constUrl" alt="">
      <el-upload
               class="upload-demo"
               :limit="1"
               :show-file-list="false"
               accept=".jpg, .jpeg, .png, .JPG, .JPEG"
               :on-success="handleSuccess"
               action="#"
               :http-request="getFile"
              :before-upload="getFile">
        <el-button plain round size="small" type="primary">点击更换头像</el-button>
       <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
      </el-upload>
    </div>
    <div class="profile_two">
      <el-form :model="profileForm" :rules="profileRules" ref="profileForm" >
        <div class="first">
          <el-form-item class="first_one" prop="nickname">
            <el-input v-model="profileForm.nickname" placeholder="昵称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                    v-model="profileForm.birthday"
                    :picker-options="pickerOptions0"
                    format="yyyy/MM/dd"
                    value-format="yyyy/MM/dd"
                    type="date"
                    placeholder="出生日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="first">
          <el-form-item class="first_one">
            <el-input v-model="profileForm.telephone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input  v-model="profileForm.email" placeholder="邮箱"></el-input>
          </el-form-item>
        </div>
        <el-form-item class="contact_sec">
          <el-input v-model="profileForm.address"  placeholder="联系地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="profile_three">
      <el-button round plain class="profile_three_btn" type="primary" @click="saveData" >保存</el-button>
      <el-button round plain class="profile_three_btn" type="primary" @click="$router.push('/home')">取消</el-button>
    </div>
 <Loading :visible="loadingVisible"/>
  </div>
</template>

<script>
  import { changePersonal, getPersonal } from '@/utils/network'
  import getBase64 from "@/utils/getBase64"
  import { imgUrl } from '@/utils/imgUrl'
  import Loading from '@/components/loading/Loading.vue'
  export default {
    name: 'Profile',
    data() {
      var checkEmail = (rule, value, callback) => {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!value) {
          return callback()
        }
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的邮箱格式'))
          }
        }, 100)
      }
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          //console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
        pickerOptions0: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
        constUrl:imgUrl,  //默认头像地址
        imgUrl:'',
        imgType:'',
        showUrl:'',
        imgUrlHou:null,
        headerObj: {
          "Access-Token": localStorage.getItem('token')
        },
        //个人信息对象
        profileForm:{
          nickname: '',
          birthday:'',
          email:'',
          address:'',
          telephone:''
        },
        //表单验证规则
        profileRules:{
          telephone:[ { validator: checkPhone, trigger: 'blur' }],
          email:[ { validator: checkEmail, trigger: 'blur' }],
          nickname:[{ required: true, message: '昵称不能为空', trigger: 'blur' },
            { max: 12, message: '长度最长12个字符', trigger: 'blur' }]
        },
        //控制遮罩
        loadingVisible:false

      }
    },
    computed:{
      //头像
      imgUrll() {
        return this.$store.state.avatar
      },
      //昵称
      nickname() {
        return this.$store.state.nickname
      },
      //生日
      birthday() {
        return this.$store.state.birthday
      },
      //id
      id() {
        return this.$store.state.id
      },
      //电话
      telephone() {
        return this.$store.state.telephone
      },
      //地址
      address() {
        return this.$store.state.address
      },
      email() {
        return this.$store.state.email
      },


    },
    async created() {
      //组件创建之前拉取个人信息
      const token = localStorage.getItem('token')
      if (token) {
        const {data: res} = await getPersonal({})
        //console.log(res)
        this.$store.dispatch('gerUserInfo', {
          user: res.data
        })
        console.log('res:',res)
      }
      //获取数据， 回显表单
      this.profileForm.nickname = this.nickname
      this.profileForm.birthday = this.birthday
      this.profileForm.telephone = this.telephone
      this.profileForm.address = this.address
      this.profileForm.email = this.email
      this.imgUrl = this.imgUrll
      //console.log(this.imgUrl)
      //console.log(this.imgUrll)
    },
    methods: {
      //获取上传图片的url
      handleSuccess(res) {
        this.imgUrlHou = res.data
      },
      //获取上传的url 地址
      async getFile(file) {
        let url = await  getBase64(file)
        this.imgUrl = url
        const index = url.indexOf(',')
        const index2 = file.name.lastIndexOf('.')
        this.imgType = file.name.substring(index2 + 1)
        this.imgUrlHou = url.substring(index + 1)
      },
      //
      async saveData() {
        //给个人信息对象添加字段
        const obj = this.profileForm
        if(this.imgUrlHou) {
          obj.avatar = this.imgUrlHou
        } else {
          obj.avatar = this.imgUrl
        }
        obj.id = this.id
        obj.imgType = this.imgType

        //发网络请求， 提交数据
        this.loadingVisible = true
        const { data: res }= await changePersonal(obj)
        this.loadingVisible = false
        this.imgUrlHou = null
        if (res.code !== 200 && res.code !== 20403) return  this.$message.error(`${res.message}`)
        if(res.code === 20403 && res.message.indexOf('authorized')) {
          localStorage.removeItem('token')
          this.$store.dispatch('gerUserInfo', {
            user:{}
          })
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })

        }else {
          this.$store.dispatch('gerUserInfo', {
            user: res.data
          })
          this.$message.success(`${res.message}`)
        }

      }
    },
    components: {
      Loading
    }
  }
</script>

<style lang="less" scoped>
  .profile {
    background-color: #fff;
    width: 80%;
    height: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    //height: 420px;
    margin: 20px auto;
    .profile_one {
      text-align: center;
      img {
        width: 125px;
        height: 125px;
        border-radius: 50%;
      }

    }
    .profile_two {
      .first {
        .first_one {
          margin-right: 20px;
        }
        display: flex;
      }

    }
    .profile_three {
      .profile_three_btn {
        width: 120px;
      }

    }
  }

</style>
